import React from "react";
import HeroMdLg from "../Assets/HeroAssets/HeroMdLg.png"
import HeroSM from "../Assets/HeroAssets/HeroSm.png"
import elise from "../Assets/HeroAssets/Ellipse55.png"
import Ellipse from "../Assets/HeroAssets/Ellipse54.png"

const Hero = () => {
    return (


        <div className="flex justify-center max-w-[1450px] mx-auto ">
            <div className=" flex justify-center flex-col w-full  ">
                <div>
                    <img src={Ellipse} alt="" className="hidden lg:inline absolute md:top-0 md:right-0"/>
                    <img src={elise} alt="" className="hidden lg:inline absolute left-0 top-56 lg:w-[600px]"/>
                </div>
                <div className=" flex justify-center  bg-lgBgd bg-no-repeat  bg-cover bg-center  ">

                    {/*Girl Picture MD and LG screen*/}

                    <img src={HeroSM} alt="" className=" md:hidden z-30"/>

                    {/*Girl Picture SM screen*/}

                    <img src={HeroMdLg} alt="" className="hidden md:inline lg:-translate-y-24 z-30"/>

                    <span className="hidden md:inline absolute w-[700px] h-[500px] lg:w-[896px] lg:h-[498px] z-20    bg-tether bg-no-repeat bg-contain bg-center "></span>
                    <h1 className="absolute top-[365px] md:top-[600px] lg:top-[560px] max-w-[900px]  w-11/12 text-center z-40 font-semibold text-xl md:text-4xl lg:text-5xl lg:leading-[60px]   ">Welcome
                        to <span className="text-[#18E996] lowercase">tether.bet </span>-The biggest stablecoin sportsbook
                        &
                        casino!
                    </h1>
                    <p className="absolute top-[460px] md:top-[700px] md:text-sm text-center w-9/12 text-xs opacity-70 z-40">Sign
                        up today and experience the ultimate thrill of crypto gaming. We offer the best odds in the
                        market and 100% secure payments.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Hero