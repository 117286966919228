import React from "react";
import placeholder from "../Assets/Section5Assets/placeholder.png";
import rectangleGreen from "../Assets/Section8Assets/RectangleGreen.png";
import rectangleBlack from "../Assets/Section8Assets/rectangleBlack.png";

import {useState} from "react";

const Section8Small6Cards = () => {
    {/*
    const [hoveredDivs, setHoveredDivs] = useState(Array(6).fill(false));

    const handleHover = (index) => {
        const updatedHoveredDivs = [...hoveredDivs];
        updatedHoveredDivs[index] = true;
        setHoveredDivs(updatedHoveredDivs);
    };

    const handleLeave = (index) => {
        const updatedHoveredDivs = [...hoveredDivs];
        updatedHoveredDivs[index] = false;
        setHoveredDivs(updatedHoveredDivs);
    };

     */}

    const divContents = [
        {
            title: "Stablecoin advantage",
            text: "Faster transactions, lower fees!",
            image: placeholder,
        },
        {
            title: "Seamless user experience",
            text: "Easy navigation guaranteed!",
            image: placeholder,
        },
        {
            title: "24/7 Customer support",
            text: "Assistance at your fingertips!",
            image: placeholder,
        },{
            title: "Quick sign-up",
            text: "Get playing in minutes!",
            image: placeholder,
        },{
            title: "Instant crypto transactions",
            text: "Speedy deposits & withdrawals!",
            image: placeholder,
        },{
            title: "Fair play guaranteed",
            text: "Licensed & certified games!",
            image: placeholder,
        },
    ];



    return (

        <div className="max-w-[1450px] -translate-y-20  flex justify-center flex-col mx-auto space-y-24 md:space-y-32 relative">
            <h1 className=" px-2.5  text-3xl md:text-5xl font-semibold   text-center z-20">
                Why choose <span className="lowercase text-[#18E996]">tether.bet</span> casino
            </h1>
            <div className="w-full px-2.5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 ">

                {/* <img src={elipse} alt="" className="absolute  -top-52 -left-1 md:-top-96 md:h-[900px] lg:-left-60 lg:w-[500px] lg:-top-56 z-10 "/> */}

                {/*6 small cards*/}

                {divContents.map((content, index) => (
                    <div key={index} className={`text-center justify-center items-center z-20 flex flex-col h-[280px] bg-white bg-opacity-10 rounded-md relative n  `}>
                        <div className="w-[125px] h-[125px] rounded-full bg-[#27272B] flex items-center justify-around border-[14px] border-black absolute -top-14 z-20">
                            <img src={content.image} alt="" />
                        </div>
                        <img src={rectangleGreen} alt="" className="absolute top-0 z-10"/>
                        <h1 className={`$ text-2xl font-bold mt-4`}>
                            {content.title}
                        </h1>
                        <p className={`$ text-base opacity-70 mt-2`}>
                            {content.text}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Section8Small6Cards