import React from "react";
import Cardpic from "../Assets/Section1Assets/CardPicture.png"
import vectorBlue from "../Assets/Section1Assets/vectorBlue.png"
import vectorRed from "../Assets/Section1Assets/vectorPurple.png"
import vectorPurple from "../Assets/Section1Assets/vectorRed.png"
import tether_monci from "../Assets/Section1Assets/tetherbet_monci-2.png"
import gospoja from "../Assets/Section1Assets/tetherbet_gospoja-2.png"

const Section1Cards = () => {


    return (
        <div className=" max-w-[1450px] flex justify-center flex-col mx-auto  mt-32 md:mt-11 ">

            {/*Button*/}

            <div className="text-center z-10">
                <a href="https://play.tether.bet/(modal:auth/register)" className="text-center ">
                    <button className="text-black font-bold border-2 border-[#18E996] bg-[#18E996] rounded-md h-11 w-28  md:w-28 lg:w-40 lg:h-16  hover:bg-[#0aaf6e]   transition ease-in-out duration-200 translate-10"> BET NOW</button>
                </a>
            </div>

            {/*Section1Cards!!!*/}

            {/*Card Sportsbook !!!*/}

            <div className="grid grid-cols-1 md:flex md:flex-col md:justify-center md:items-center  lg:grid lg:grid-cols-3 p-5 mt-24 mb-11 gap-24">
                <div className="flex flex-col justify-center items-center  text-center border border-[#7144D4] rounded-tr-md rounded-br-md rounded-bl-md  p-2 relative bg-section1 bg-cover bg-right-bottom bg-no-repeat bg-gray-600 bg-blend-soft-light bg-opacity-40 ">
                    <img src={vectorPurple} alt="" className="absolute top-0 left-0"/>

                    <img src={tether_monci} alt="" className="translate-x-3 -translate-y-20   "/>

                    <div className="-translate-y-8 text-center">
                        <h1 className=" -translate-y-10 font-semibold text-3xl">Sportsbook</h1>
                        <p className="text-sm opacity-70 -translate-y-6 mx-3 ">Unmatched sportsbook: 15,000+ markets, USDT betting. </p>
                        <a href="https://play.tether.bet/sports/prematch#/prematch/soccer/sr:category:4/all" className="-translate-y-2">
                            <button className=" border-2 border-[#7144D4] bg-[#7144D4] rounded-md h-11 w-[185px] md:w-40 font-semibold text-sm  hover:bg-[#6c44be]">Visit Our Sportsbook</button>
                        </a>
                    </div>
                </div>

                {/*Card Casino !!!*/}

                <div className="flex flex-col justify-center items-center    text-center border border-[#3B31B5] rounded-tr-md rounded-br-md rounded-bl-md  p-2 relative bg-section1 bg-cover bg-right-bottom bg-no-repeat bg-gray-600 bg-blend-soft-light bg-opacity-40">
                    <img src={vectorBlue} alt="" className="absolute top-0 left-0"/>
                    <img src={Cardpic} alt="" className="translate-x-3 -translate-y-20  "/>
                    <div className="-translate-y-8">
                        <h1 className=" -translate-y-10 font-semibold text-3xl">Casino</h1>
                        <p className="text-sm opacity-70 -translate-y-6 mx-3 ">Exclusive gaming: 5,000+ games, endless entertainment. </p>
                        <a href="https://play.tether.bet/casino/games" className="-translate-y-2">
                            <button className=" border-2 border-[#3B31B5] bg-[#3B31B5] rounded-md h-11 w-[185px] md:w-40 font-semibold text-sm hover:bg-[#302c83] ">Visit Our Casino</button>
                        </a>
                    </div>
                </div>

                {/*Card LiveCasino !!!*/}

                <div className="flex flex-col justify-center items-center   text-center border border-[#ED1A5E]  rounded-tr-md rounded-br-md rounded-bl-md  p-2 md:p-5 lg:p-2 relative bg-section1 bg-cover bg-right-bottom bg-no-repeat bg-gray-600 bg-blend-soft-light bg-opacity-40">
                    <img src={vectorRed} alt="" className="absolute top-0 left-0"/>
                    <img src={gospoja} alt="" className=" -translate-y-20 "/>
                    <div className="-translate-y-8">
                        <h1 className=" -translate-y-10 font-semibold text-3xl">Live Casino</h1>
                        <p className="text-sm opacity-70 -translate-y-6 mx-3 ">Live casino thrills: Endless excitement awaits you here.</p>
                        <a href="https://play.tether.bet/casino/live-casino" className="-translate-y-2">
                            <button className=" border-2 border-[#ED1A5E] bg-[#ED1A5E] rounded-md h-11 w-[185px] md:w-40 font-semibold text-sm hover:bg-[#c7094b] ">Visit Our Live Casino</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1Cards