import React from "react";
import Vector from "../Assets/Section3Assets/Vector1.png";
import elipse from "../Assets/Section2Assets/Ellipse57.png";
import livecasino from "../Assets/Section5Assets/live-casino.png";
import smbgdf from "../Assets/Section5Assets/Ellipse60.png";
import deposit from "../Assets/Section5Assets/deposit.png";
import wellcome from "../Assets/Section5Assets/bonus.png";
import accaunt from "../Assets/Section5Assets/tetherbet_account-2.png";
const Section5CasinoAndRegistrationCard = () => {
    return (
        <div className="max-w-[1450px]   flex  flex-col  mb-11  mx-auto ">

            {/*Card!!!

             <div className="bg-[rgba(39,39,43,255)] w-full mb-11  lg:h-[485px] p-5 lg:p-14   flex flex-col lg:flex-row justify-center items-center z-20   rounded-tl-md rounded-br-md rounded-bl-md relative ">


                    <div className="flex-1 lg:order-1">
                        <img src={livecasino} alt="" className="   "/>
                    </div>
                <img src={Vector} alt="" className="absolute top-0 right-0 w-[63px] h-[59px] "/>
                <div className=" flex-1 relative ">
                    <h4 className=" text-3xl md:text-4xl font-semibold mb-5 ">
                        Unleash the best crypto live casino experience!
                    </h4>



                    <div className="space-y-3 mb-5">
                        <div className="flex flex-row items-center space-x-3  ">
                            <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                            <h1 className="text-xl leading-[35px]"> Instant Crypto Transactions!</h1>
                        </div>
                        <p className="text-sm opacity-70 ">Fast deposits and withdrawals are just a few clicks
                            away. Say
                            goodbye
                            to waiting for days for
                            traditional banks to process your transactions. At tether.bet, as a crypto gaming platform,
                            we
                            process your deposits and withdrawals within minutes. Experience the simplicity, speed, and
                            security of crypto payouts.
                        </p>
                    </div>


                    <div className="space-y-3">
                        <div className="flex flex-row items-center space-x-3">
                            <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                            <h1 className="text-xl leading-[35px]"> Exclusive Rewards Await!</h1>
                        </div>
                        <p className="text-sm opacity-70  ">Unlock the power of bonuses and promotions at
                            tether.bet. We
                            guarantee
                            you won't find better welcome bonuses and promotions on any other crypto gaming website.
                            Join us
                            and
                            discover the exciting rewards we have in store for you.
                        </p>
                    </div>
                </div>
            </div>

            */}

            {/*Register explanation !!! */}

            <div className='flex justify-center items-center flex-col  mt-11 md:mt-20   '>
                <h1 className=" text-2xl md:text-5xl font-semibold px-2.5  text-center mb-11 md:mb-20 ">
                    Get in the game: Register with <span className="lowercase text-[#18E996]">tether.bet</span>
                </h1>
                <div className="flex justify-around  w-full items-center flex-col lg:flex-row z-10 lg:mt-20  ">

                    {/*Register step 1*/}
                    <div className="flex flex-col flex-1 items-center text-center mb-10 lg:mb-0  ">
                        <a href="https://play.tether.bet/(modal:auth/register)">
                            <div className="flex flex-col flex-1 items-center text-center  w-full  mb-10 lg:mb-0  ">
                                <div className="w-[125px] h-[125px] rounded-full bg-[#27272B] flex items-center justify-around mb-10 ">
                                    <img src={accaunt} alt=""/>
                                    <img src={smbgdf} alt="" className="absolute"/>
                                 </div>
                                <p className="text-xl leading-[35px] text-[#18E996] capitalize">Open an account</p>
                                <p>Register with <span className="text-[#18E996] lowercase">tether.bet </span> today</p>
                            </div>
                        </a>
                    </div>
                    {/*Register step 2*/}

                    <div className="flex flex-col flex-1 items-center text-center mb-10 lg:mb-0  ">
                        <a href="https://play.tether.bet/(modal:finances/deposit/cryptopay)">
                            <div className="flex flex-col flex-1 items-center text-center mb-10 lg:mb-0  ">
                                <div className="w-[125px] h-[125px] rounded-full bg-[#27272B] flex items-center justify-around  mb-10">
                                    <img src={deposit} alt=""/>
                                    <img src={smbgdf} alt="" className="absolute"/>
                                </div>
                                <p className="text-xl leading-[35px] text-[#18E996]">Deposit</p>
                                <p>Deposit 1 USDT or more</p>
                            </div>
                        </a>
                    </div>
                    {/*Register step 3*/}
                    <div className="flex flex-col flex-1 items-center  text-center ">
                        <a href="https://play.tether.bet/promotions">
                            <div className="flex flex-col flex-1 items-center  text-center  ">
                                <div className="w-[125px] h-[125px] rounded-full bg-[#27272B] flex items-center justify-around mb-10 ">
                                    <img src={wellcome} alt=""/>
                                    <img src={smbgdf} alt="" className="absolute"/>
                                </div>
                                <p className="text-xl leading-[35px] text-[#18E996] ">Welcome Bonus</p>
                                <p>Sign up and start scoring</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Section5CasinoAndRegistrationCard;