import React from "react";
import board from "../Assets/Section6Assets/image72.png"


const Section6GreenBackground = () => {
    return (
        <div className="flex md:justify-center flex-col md:flex-row w-full mx-auto bg-[#18E996] h-[320px] lg:h-[400px] mt-20 lg:mt-40 relative">
            <img src={board} alt="" className="hidden absolute lg:inline left-0 h-[400px]"/>
            <div className="space-y-5 mt-10 md:mt-16">
                <h1 className="text-3xl md:text-6xl font-semibold  text-center">
                    <span className="text-black">Welcome</span> to tether.bet </h1>
                <p className="text-black text-center ">Unbeatable bonuses for sportsbook & casino!</p>
            </div>
        </div>
    );

};

export default Section6GreenBackground;