import React from "react";
import vector from "../Assets/Section9Assets/Vector1.png"
import vector2 from "../Assets/Section9Assets/Vector2.png"
import line from "../Assets/Section9Assets/Line.png"
import lineHor from "../Assets/Section9Assets/LineHor.png"


const Section9LastSection = () => {
    return (
        <div className="max-w-[1450px]    flex justify-center  flex-col px-2.5 lg:px-0 mx-auto   ">
            <div className="w-full flex lg:h-[550px]  flex-col justify-center rounded-md bg-gradient-to-b from-[#2B1D4B] to-[#27272B] relative text-center mx-auto  ">

                {/*<img src={background} alt="" className="absolute hidden lg:inline lg:w-[300px] left-72 bottom-20 opacity-20"/>*/}
                <h1 className="text-3xl  lg:text-4xl font-semibold   px-2 mt-14 md:mb-10 lg:mt-28 ">Join the crypto gaming revolution at <span className="lowercase text-[#18E996]">tether.bet</span> today!</h1>
                <img src={vector} alt="" className="absolute top-0 right-0 w-[63px] h-[59px] lg:w-[123px] lg:h-[115px] "/>
                <img src={vector2} alt="" className="hidden md:inline absolute bottom-0 left-0 w-[63px] h-[59px] lg:w-[123px] lg:h-[115px] "/>

                <div className="grid grid-cols-1 lg:grid-cols-3 text-center p-4 lg:translate-y-2 ">
                    <div className="flex flex-col relative mb-10">
                        <h1 className="text-xl md:text-xl font-bold mt-4  mb-4">High stakes, Fast payments</h1>
                        <p className="text-base opacity-70 mb-5 md:mt-5 md:mb-10 md:mx-10 lg:mx-0 lg:p-5">Experience the pinnacle of high-stakes gambling and enjoy the unmatched speed of crypto payments at <span className="text-[#18E996] lowercase">tether.bet.</span>
                        </p>
                        <img src={line} alt="" className="absolute -bottom-5 w-full md:ml-24 md:w-3/4 lg:hidden "/>
                        <img src={lineHor} alt="" className="hidden lg:inline absolute right-0"/>
                    </div>
                    <div className="flex flex-col relative mb-10">
                        <h1 className="text-xl md:text-xl font-bold mt-4 mb-4">Best VIP experience</h1>
                        <p className="text-base opacity-70 mb-5 md:mt-5 md:mb-10 md:mx-10 lg:mx-0 lg:p-5">Immerse yourself in the epitome of VIP treatment and indulge in unforgettable rewards at <span className="text-[#18E996] lowercase">tether.bet. </span>
                        </p>
                        <img src={line} alt="" className="absolute -bottom-5 w-full md:ml-24 md:w-3/4 lg:hidden"/>
                        <img src={lineHor} alt="" className="hidden lg:inline absolute right-0"/>
                    </div>
                    <div className="flex flex-col relative">
                        <h1 className="text-xl md:text-xl font-bold mt-4  mb-4">Fair player, Fast payer</h1>
                        <p className="text-base opacity-70 mb-5 md:mt-5 md:mb-10 md:mx-10 lg:mx-0 lg:p-5">Embrace fair play and be assured of swift payouts when you choose <span className="text-[#18E996] lowercase">tether.bet </span> as your gaming destination.
                        </p>
                    </div>
                </div>

                <div className="lg:-translate-y-5">
                    <a href="https://play.tether.bet/(modal:auth/register)">
                        <button
                            className="w-[230px] h-[55px] text-black font-semibold bg-[#18E996] rounded-md md:my-10 mb-5 hover:bg-[#0aaf6e] hover:border-[#18E996] hover:border-2">START
                            PLAYING NOW
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Section9LastSection