import React from "react";
import elipse from "../Assets/Section2Assets/Ellipse57.png"

import rulet from "../Assets/Section2Assets/rulet.png"


const Section2FutureOfGaming = () => {

    return (
        <div className=" max-w-[1450px] p-5 lg:p-14  lg:h-[485px] flex justify-center items-center  flex-col lg:flex-row mx-auto  ">

            {/*Image on the left side*/}
            <div className="flex-1">
            <img src={rulet} alt="" className="   "/>
            </div>
            <div className=" flex-1">
                <h4 className=" text-3xl md:text-4xl font-semibold mb-5  ">The future of gaming is here!</h4>

                {/*First part */}

                <div className="space-y-3 mb-5">
                    <div className="flex flex-row items-center space-x-3 ">
                        <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                        <h1 className="text-xl leading-[35px]"> Instant crypto transactions!</h1>
                    </div>
                    <p className="text-sm opacity-70  ">
                        Fast deposits and withdrawals are just a few clicks
                        away. Say
                        goodbye
                        to waiting for days for
                        traditional banks to process your transactions. At tether.bet, as a crypto gaming platform, we
                        process your deposits and withdrawals within minutes. Experience the simplicity, speed, and
                        security of crypto payouts.
                    </p>
                </div>

                {/*Second part */}

                <div className="space-y-3 ">
                    <div className="flex flex-row items-center space-x-3">
                        <img src={elipse} alt="" className="w-[15px] h-[15px]"/>
                        <h1 className="text-xl leading-[35px]"> Exclusive rewards await!</h1>
                    </div>
                    <p className="text-sm opacity-70  ">
                        Unlock the power of bonuses and promotions at
                        tether.bet. We
                        guarantee
                        you won't find better welcome bonuses and promotions on any other crypto gaming website. Join us
                        and
                        discover the exciting rewards we have in store for you.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Section2FutureOfGaming